import "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterSection from '../components/FooterSection';
import Spacer from '../components/Spacer';
import { useParams } from "react-router-dom";

function Stamp() {

  let { id } = useParams();
  
  return (
      <div className="App" id="stamp-page">
        {id}
        <Spacer/>
        <FooterSection/>
      </div>
  );
}

export default Stamp;
