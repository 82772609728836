import faqHeader from "../assets/FAQ.png"
import Spacer from "./Spacer";
import { Container } from "react-bootstrap";

export default function FAQSection(){

    return(


        <Container id="faq-section">

            <img src={faqHeader} alt="FAQ section header" className="header_image" />
            <Spacer/>
            <div className="container" style={{width: "80%"}}>
                <div className="row">
                    <div className="col">
                        <h3>What is the Ethereum Stamp Factory?</h3>
                        <ul className="list-unstyled">
                            <li>The Eth Stamp Factory is a collection of 122 unique linocut stamps living on the Ethereum blockchain. Each NFT is a print of the physical linocut stamp that realizes the NFT's attributes. For example, a "green" "2x2" "scooby doo" NFT will have green ink, be 2 inches by 2 inches, and the image will be some kind of scooby doo content. Different sizes might have different content, that is to say a "2x2" "scooby doo" might be different than a "4x6" "scooby doo".</li>
                        </ul>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h3>How does minting work, and how much does it cost?</h3>
                        <ul className="list-unstyled">
                            <li>You will mint an Ethereum Stamp Factory NFT from this site in the Mint section <a href="/#mint-section" style={{textDecoration: "none"}}>above</a>. For the PRESALE (30 stamps): each Stamp will cost .04 ETH, and the max number to mint per wallet is 3. For the PUBLIC SALE (89 stamps): each Stamp will cost .06 and the max number to mint per wallet is 5.</li>
                        </ul>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h3>What can I do with my Stamp and NFT?</h3>
                        <ul className="list-unstyled">
                            <li>The NFT is yours to do with as you wish, but if we follow the Roadmap, I will be making special merch out of the hand-carved stamps for their holders (free of charge)! What you decide to do with the physical stamp is also up to you, but I'd recommend using it!</li>
                        </ul>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h3>What are Wildcard Contents?</h3>
                        <ul className="list-unstyled">
                            <li>Wildcards are special! YOU get to decide what goes on that stamp - we will work together on a special stamp of your design! Naturally, we will keep it in the scope of the stamp size, but I want to keep it open for your interpretation.</li>
                        </ul>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h3>Do I get anything with the NFT?</h3>
                        <ul className="list-unstyled">
                            <li>Yes! After 30% of stamps are minted, I will commit to uploading the stamp carving videos to the website, and the <a href="https://www.instagram.com/ethstampfactory/" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">Eth Stamp Factory's Instagram</a> page. At +60% everyone will be getting their physical stamps (and a few other small goodies) sent to them for free, and at 100% I'll be including t-shirts, stickers, and several "category" prizes in the merch bundles.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Container>

    );
};
