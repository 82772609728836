import Spacer from "./Spacer";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import twitter_logo from "../assets/twitter-logo-black.svg";
import discord_logo from "../assets/Discord-Logo-Black.svg";
import pawlak_logo from "../assets/pawlak_stamp.png";
import opensea_logo from "../assets/opensea-logo.png"
import { Container, Row, Col } from "react-bootstrap";


export default function FooterSection(){
    return(
        <Container id="footer-section">
            <hr/>
            <Row>
                <Spacer/>
                <Col>
                    <Navbar>
                        <Nav style={{ width: "95%"}}>
                            <Nav.Item>
                                <Nav.Link href="https://twitter.com/ethstampfactory"  target="_blank" rel="noreferrer">
                                    <img height="15px" width="15px" src={twitter_logo} alt="twitter logo as link to twitter account"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="https://discord.gg/XUF3TGhqyk" target="_blank" rel="noreferrer">
                                    <img height="15px" width="15px" src={discord_logo} alt="discord logo as invite link to server"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                href="https://opensea.io/collection/eth-stamp-factory"
                                target="_blank"
                                rel="noreferrer"
                                >
                                    <img
                                        height="15px"
                                        width="15px"
                                        src={opensea_logo}
                                        alt="opensea logo as link to opensea collection"
                                    />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/#home">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/#mint-section">Mint</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/#whitepaper">Whitepaper</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                </Col>
                <Col>
                    <Navbar>
                        <Nav className="justify-content-end" style={{ width: "100%"}}>
                            <Nav.Item>
                                <Nav.Link href="https://jacobpawlak.com"  target="_blank">
                                    A <img height="30px" src={pawlak_logo} alt="pawlak productions logo as link to jacobpawlak.com"/> Productions Site.
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    );
};
