import your_stamps from "../assets/your_stamps.png"
import Spacer from "./Spacer";
import { useWeb3React } from '@web3-react/core'

export default function MyStampSection() {
    const { account } = useWeb3React();

    return (
        <div className="container" id="my-stamp-section" style={{}}>
            <div className="row">
                <div className="col" style={{ width: "90%" }}>
                    <img src={your_stamps} alt="Your stamps header" style={{ width: "30%" }} />
                </div>
            </div>
            <Spacer />
            <div className="row" style={{ width: "95%", textAlign: "left" }}>
                <div className="col">
                    <p>Wallet Address: <a href={"https://etherscan.io/address/" + account} style={{ textDecoration: "none" }} >{account}</a></p>
                </div>
                <div className="col">
                    <p>Your Opensea Collection: <a href={"https://opensea.io/" + account} style={{ textDecoration: "none" }} >{account}</a></p>
                </div>
            </div>
            <Spacer />
        </div>
    );

};
