import mintHeader from "../assets/Mint.png";
import colorsImage from "../assets/colors_small.png";
import contentsImage from "../assets/contents_small.png";
import sizesImage from "../assets/sizes_small.png";
import Spacer from "./Spacer";
import Button from 'react-bootstrap/Button';
import { useWeb3React } from "@web3-react/core";
import StampFactoryABI from '../abis/StampFactory.json';
import { Contract, utils } from 'ethers';
import { useState, useEffect, useMemo } from 'react';
import Slider from "react-input-slider";
import { Row, Col } from "react-bootstrap";


/* Mint State */
// idle
// noSaleActive
// presaleActive
// saleActive
// loading
// success
// error

/* Sale State */
// null
// presale
// public

export default function MintSection() {
    const { library: provider, account } = useWeb3React();

    const PRESALE_MAX_MINCE = 3
    const PRESALE_PRICE = 0.04

    const PUBLIC_MAX_MINCE = 5
    const PUBLIC_PRICE = 0.06

    const [saleState, setSaleState] = useState(null)
    const [mintState, setMintState] = useState("idle")
    const [txnHash, setTxnHash] = useState(null)
    const [stampsToMint, setStampsToMint] = useState(2)
    const [presaleStampsMinted, setPresaleStampsMinted] = useState(0)
    const [totalStampsMinted, setTotalStampsMinted] = useState(0)
    const [usersPresaleMincePerWallet, setUsersPresaleMincePerWallet] = useState(0)
    const [usersMincePerWallet, setUsersMincePerWallet] = useState(0)

    const maxMince = (saleState === 'presale' ? PRESALE_MAX_MINCE : PUBLIC_MAX_MINCE)
    const priceToMint = (saleState === 'presale' ? PRESALE_PRICE : PUBLIC_PRICE) * stampsToMint

    const stampFactoryContract = useMemo(() => {
        return new Contract('0x9b79A1ecDA904beDAFF56D4f4A4A6510Dbdbb805', StampFactoryABI, provider)
    }, [provider])

    /* Pull in a ton of stuff */
    useEffect(() => {
        if (stampFactoryContract == null || mintState !== "idle") {
            return;
        }

        /* Sale Status */
        stampFactoryContract.presaleActive().then(presaleActive => {
            if (presaleActive === true) {
                setSaleState('presale')
                setMintState('active')
            } else {
                stampFactoryContract.saleActive().then(saleActive => {
                    if (saleActive === true) {
                        setSaleState('public')
                        setMintState('active')
                    }
                }).catch(e => {
                    console.log(e)
                })
            }
        }).catch(e => {
            console.log(e)
        })

        //grabbing the users presale and public mint count from contract
        stampFactoryContract.presaleMincePerWallet(account).then(result => {
            setUsersPresaleMincePerWallet(result)
        }).catch(e => {
            console.log(e)
        })
        stampFactoryContract.mincePerWallet(account).then(result => {
            setUsersMincePerWallet(result)
        }).catch(e => {
            console.log(e)
        })

        /* Mint Progress */
        stampFactoryContract.presaleMints().then(result => {
            setPresaleStampsMinted(result)
        }).catch(e => { })
        stampFactoryContract.totalSupply().then(result => {
            setTotalStampsMinted(result)
        }).catch(e => { })

    }, [stampFactoryContract, mintState, account])

    function tryMint() {
        if (stampFactoryContract == null) {
            console.log("Stamp Factory Contract is null")
            return
        }

        const sendValue = utils.parseEther(priceToMint.toString())

        setMintState('loading')
        const connectedContract = stampFactoryContract.connect(provider.getSigner())
        const mint = saleState === 'presale' ? connectedContract.presaleMintStamp : connectedContract.mintStamp

        mint(stampsToMint, {
            value: sendValue,
        }).then(result => {
            if (result.hash != null) {
                result.wait().then(() => {
                    setMintState('success')
                    setTxnHash(result.hash)
                })
            }
        })
            .catch(error => {
                setMintState('error')
                console.log(error)
            })
    }

    function getButtonDisabled(mintState) {
        switch (mintState) {
            case 'active':
            case 'error':
                return false;
            default:
                return true;
        }
    }

    function getButtonTitle(mintState) {
        switch (mintState) {
            case 'active':
                switch (saleState) {
                    case 'presale':
                        return 'Presale Mint'
                    case 'public':
                        return 'Mint'
                    default:
                        return 'Connect Wallet Above'
                }
            case 'loading':
                return 'Minting...'
            case 'success':
                return 'Minted.'
            case 'error':
                return 'Try Again.'
            default:
                return 'Connect Wallet Above'
        }
    }

    return (
        <div className="container" id="mint-section" style={{ width: "90%" }}>
            <img src={mintHeader} alt="mint section header" className="header_image" />
            <Spacer />
            <div className="row">
                <div className="col" style={{ width: "100%" }}>
                    {txnHash != null ? <a href={`https://etherscan.io/tx/${txnHash}`} style={{ textDecoration: "none" }} rel="noreferrer">Your stamp was minted! The transaction can be viewed here.</a> :
                        <Button disabled={getButtonDisabled(mintState)} variant="outline-dark" size="lg" onClick={tryMint}>{getButtonTitle(mintState)}</Button>
                    }
                </div>
                {saleState != null ? <div>
                    <br />
                    <p>You're going to mint <strong>{stampsToMint}</strong> stamp(s) for {priceToMint} ETH</p>
                    <br />
                    <Slider
                        axis="x"
                        xstep={1}
                        xmin={1}
                        xmax={maxMince}
                        x={stampsToMint}
                        onChange={({ x }) => setStampsToMint(x)}
                        styles={{ track: { backgroundColor: 'white' }, active: { backgroundColor: '#76ae4d' }, thumb: { backgroundColor: "black" } }}
                    />
                    <Spacer />
                    {saleState === "presale" ? `There are ${presaleStampsMinted} of 30 presale stamps minted - you've minted ${usersPresaleMincePerWallet} out of 3! ` :
                        `There are ${totalStampsMinted} stamps minted of 122. You've minted ${Number(usersPresaleMincePerWallet) + Number(usersMincePerWallet)} stamps ... ${usersPresaleMincePerWallet} of 3 from the presale and ${usersMincePerWallet} of 5 from the public sale!`}
                </div> : <></>}
                <Spacer />
                <h2>When you mint an Ethereum Stamp Factory NFT you will see:</h2>
                <p><strong>A Template Image -</strong> your NFT will be updated with the hand-carved version as soon as it is finished!</p>
                <br />
                <br />
                <h2>On Reveal Day (2 days after the presale closes)</h2>
                <p><strong><i>THE</i> Stamp Image -</strong> this is your hand-carved stamp (a print) realized from the attributes that you see listed below. </p>
                <p><strong>Size attribute -</strong> it will matter <i>a lot</i> for merch!</p>
                <p><strong>Content attribute -</strong> what gets stamped on your merch (and NFT)!</p>
                <p><strong>Color attribute -</strong> this determines the ink color!</p>
            </div>
            <Spacer />

            <Row>

                <Col sm={12} lg={4}>

                    <img src={sizesImage} alt="reference drawing for sizes" width="100%" />
                    <strong>Sizes (in inches):</strong> 2x1 (60), 2x2 (44), 3x4 (10), 4x4 (4), 4x6 (4)
                    <Spacer />

                </Col>
                <Col sm={12} lg={4}>

                    <img src={contentsImage} alt="reference drawing for contents" width="100%" />
                    <strong>Contents:</strong> NASA Worm (25), "Pawlak" (21), Mondrian CiRBY (15), Mini Cooper (9), Rainbow (9), Kanye Bear (7), MF DOOM (7), Lord Quas (5), Zig-Zag (5), "i.s.r.u" (4), Scooby-Doo (4), 5th Element (4), Apple (3), *Wildcard* (3), LANCE (1)
                    <Spacer />

                </Col>
                <Col sm={12} lg={4}>

                    <img src={colorsImage} alt="reference drawing for colors" width="100%" />
                    <strong>Colors:</strong> Black (50), Red (15), Green (15), Blue (15), Pink (6), Yellow (6), Orange (6), Silver (4), Gold (4), INVERTED (1)
                    <Spacer />

                </Col>
            </Row>
        </div>
    );
};
