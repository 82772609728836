import theEthStampFactory from "../assets/the_eth_stamp_factory.png";
import Carousel from 'react-bootstrap/Carousel'
import stamp1 from "../assets/stamp1.png"
import stamp3 from "../assets/stamp3.png"
import stamp5 from "../assets/stamp5.png"
import { Container, Row, Col } from "react-bootstrap";


export default function AboutSection(props){

    return(
        <Container id="about-section" fluid={true}>
            <Row>
                <Col sm={12} lg={6} style={{padding: "5%"}}>
                    <div className="about-left-side" style={{textAlign: "left"}}>
                        <img src={theEthStampFactory} style={{width: "100%", marginBottom: "20px"}} alt="The Stamp Factory Header"/>
                        <br/>
                        <p>Welcome! The Ethereum Stamp Factory is a collection of 122 NFTs that correspond to real-life, hand-carved linocut stamps living on the blockchain.</p>
                        <p>Stamps will be used to make prints and merch for their holders, as access to future factories, and of course, whatever else owners wish to do with their physical and NFT stamp.</p>
                        <p>Website and stamps made by me - <a className="text-decoration-none" href="https://www.jacobpawlak.com" target='_blank' rel="noreferrer">Jacob Pawlak</a>, in my apartment/studio. You can read more about me on my website, but I am an artist, #nikecraft athlete, and swe/linguist by trade.</p>
                        <p>I have been making linocut stamps for over a year: I hand draw each stamp design before carving it out of a linoleum block. Each stamp and NFT will be unique not necessarily because of attributes, but because I cannot carve identical copies of any one stamp. Below is a sample 3 of 12 NASA Worm stamps I carved for my #NCWT friends to illustrate what I mean.</p>
                        <Carousel variant="dark">
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={stamp1} alt="First slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={stamp3} alt="Second slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={stamp5} alt="Third slide"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </Col>
                <Col sm={12} lg={6} style={{padding: "5%"}}>
                    <div className="about-right-side">
                        <iframe width="90%" height="700px" src={props.youtubeLink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Stamp Factory Welcome Video"/>
                    </div>
                </Col>
            </Row>
        </Container>
    );

};
