import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import logo_small from '../assets/logo_small.png'
import Button from 'react-bootstrap/Button'
import twitter_logo from '../assets/twitter-logo-black.svg'
import opensea_logo from "../assets/opensea-logo.png"
import discord_logo from '../assets/Discord-Logo-Black.svg'
import { useWeb3React } from '@web3-react/core'
import { useEagerConnect, useInactiveListener } from '../hooks/hooks'
import { injected } from '../constants/connectors'
import { useState } from 'react'

export default function TopNav() {
  const context = useWeb3React()
  const { account, activate } = context

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState(injected)

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector)

  function onClickConnectWallet() {
    setActivatingConnector(injected)
    activate(injected)
  }

  return (
    <div>
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="/#home" style={{ padding: '20px' }}>
            <img
              src={logo_small}
              className="d-inline-block align-top"
              alt="Ethereum Stamp Factory Logo - Small"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="justify-content-end"
              style={{ width: '95%', margin: '30px' }}
            >
              <Nav.Item>
                <Nav.Link
                  href="https://twitter.com/ethstampfactory"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    height="25px"
                    width="25px"
                    src={twitter_logo}
                    alt="twiiter logo as link to twitter account"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://discord.gg/XUF3TGhqyk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    height="25px"
                    width="25px"
                    src={discord_logo}
                    alt="discord logo as invite link to server"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://opensea.io/collection/eth-stamp-factory"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    height="25px"
                    width="25px"
                    src={opensea_logo}
                    alt="opensea logo as link to opensea collection"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#home">Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#about-section">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#mint-section">Mint</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#roadmap-section">Roadmap</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/#whitepaper">Whitepaper</Nav.Link>
              </Nav.Item>

              {account ? (
                <Nav.Item>
                  <Nav.Link href="/#mint-section">
                    <Button variant="dark">
                      {`${account.substring(0, 6)}...${account.substring(
                        account.length - 4,
                      )}`}
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Button className="nav_cw_button" onClick={onClickConnectWallet} variant="outline-dark">
                  Connect Wallet
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
