import Spacer from "./Spacer";
import whitepaperHeader from "../assets/Whitepaper.png";
import profitSplit from "../assets/profit_split.png";

export default function Whitepaper(){
    return(
        <div className="container" id="whitepaper" style={{width: "90%"}}>
            <img src={whitepaperHeader} alt="Whitepaper page header" className="header_image"/>
            <Spacer/>
            <div className="container" style={{width: "80%"}}>
                <div className="row">
                    <div className="col">
                        <h2>I'm only interested in <strong>Full Transparency</strong></h2>
                        <p>The smart contract can be found here: <a className="text-decoration-none" href="https://etherscan.io/token/0x9b79A1ecDA904beDAFF56D4f4A4A6510Dbdbb805" target="_blank" rel="noreferrer">on Etherscan</a>, please only mint from this contract.</p>
                        <p>I have purchased an ENS domain for the Eth Stamp Factory's wallet - <a className="text-decoration-none" href="https://app.ens.domains/name/thestampfactory.eth/details" target="_blank" rel="noreferrer">thestampfactory.eth</a> - this is the only wallet used to maintain the project.</p>
                        <Spacer/>
                        <h2>Post-mint Funds Distribution</h2>
                        <img src={profitSplit} alt="Profit split - 50% to merch and maintenance, 25% to matt for future art, 25% to the dev team" id="whitepaper_image"/>
                        <p><strong>50% -</strong> Kept in the wallet (and partially cashed out) to pay for the merch blanks and supplies, service fees, website maintenance, etc.</p>
                        <p><strong>25% -</strong> Cashed out to my brother for artwork for our future project (remember, stamps are whitelist for <i>the next</i> project).</p>
                        <p><strong>25% -</strong> Transfered to the dev for their solidity and web3 work.</p>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h2>Price</h2>
                        <p>The Eth Stamp Factory will be holding a presale for 30 stamps at a price of .04 Eth per stamp (not including minting fees). After 24 hours, the presale will close and the public sale will open for the remaining stamps at .06 Eth each.</p>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h2>Eth Stamp Factory Minting</h2>
                        <p>The Factory will mint 3 stamps that will be airdropped to the winners of the 2 giveaways (to be a part of the giveaways you need to be a member of the Discord server, and following us on Twitter).</p>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h2>Merch</h2>
                        <p>All shirt blanks will be purchased from <a className="text-decoration-none" href="https://www.royalapparel.net/product/5051ORG/Unisex-Organic-Short-Sleeve-Tee.html" target="_blank" rel="noreferrer">Royal Apparel.</a> Shirt Sizes will be collected on Discord and ordered appropriately - If you do not select a shirt, a Uni-sex Large will be used as default. Special surprises will be included with every stamp's bundle.</p>
                    </div>
                </div>
                <Spacer/>
                <div className="row">
                    <div className="col">
                        <h2>Royalties</h2>
                        <p>Opensea royalties will be set at 10% (NOT including the 2.5% from OS). Stamps are here for the long run.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};