import Carousel from 'react-bootstrap/Carousel';
import Spacer from "./Spacer";
import previousWork from "../assets/previous_work.png";
import collection1 from "../assets/collection1-min.jpg";
import stamp2sticker from "../assets/stamp2sticker-min.jpg";
import rocketfactory1 from "../assets/rocketfactory1-min.jpg";
import rocketfactory2 from "../assets/rocketfactory2-min.jpg";
import brads1 from "../assets/brads1-min.jpg";
import janes1 from "../assets/janes1-min.jpg";
import smith1 from "../assets/smith1-min.jpg";
import nasaafter from "../assets/12nasaafter-min.jpg";
import nasawormzine from "../assets/nasawormzine-min.jpg";
import hellokitty from "../assets/hellokitty-min.jpg";
import catdome from "../assets/catdome-min.jpg";
import { Container, Row, Col } from 'react-bootstrap';



export default function PreviousWork(){

    return(

        <Container id="previous-work-section">

            <img src={previousWork} className="header_image" style={{ marginBottom: "20px"}} alt="Previous Work Header"/>
            <Spacer/>
            <Row className="align-items-center">
                <Col sm={12} lg={6} style={{padding: "5%"}} >
                    <div className="prev-work-left-side" style={{textAlign: "left"}}>
                        <Carousel >
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={collection1} alt="First slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={stamp2sticker} alt="Second slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={rocketfactory1} alt="Third slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={rocketfactory2} alt="Third slide"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>

                </Col>
                <Col sm={12} lg={6} style={{padding: "5%"}} >

                    <div className="prev-work-right-side">
                        <p>I'm including some of my previous work from the past year or so to give you an idea of what your stamps will look like, what sizes (and detail) you can expect from these stamps, and even a little sneak peek on some merch.</p>
                        <br/>
                        <p>In this group of photos you can see some carved stamps I have made for other projects and merch, a few "sticker-stamps" that I like to give away and slap around town, and a couple prints of an ode to the Tom Sachs Rocket Factory.</p>
                        <br/>
                        <p>When I am finished carving all the stamps for the Eth Stamp Factory, I am going to make a few large pieces of art to auction off for charity. These will include a large collage of all the stamps, a fancy zine that I will be selling, staring all of your stamps (proceeds going to charity), and several others!</p>
                    </div>

                </Col>
            </Row>
            <Spacer/>
            <Row className="align-items-center">
                <Col sm={12} lg={6} className="prev_work_text" style={{padding: "5%"}} >
                    <div className="prev-work-left-side">
                        <p>Now let's look at some of the stamps' applications, especially as they relate to this project which is designed around making custom merch and goodies.</p>
                        <br/>
                        <p>I have designed and stamped several shirts (some previous styles are <a href="https://jacobpawlak.com/t-shirts/" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">still for sale)</a>, so I am confident that I can make some badass shirts with your stamps. My favorite style is to use a clean, white, oversized shirt, and add a few blocks of color and then the main content image(s).</p>
                        <br/>
                        <p>With a little help and a nudge from some friends, I have been trying my hand at zine making, so I'm including my first zine "12 Nasa Worm Stamps for My <a href="https://www.instagram.com/explore/tags/nikecraftweartester/" style={{textDecoration: "none"}} target="_blank" rel="noreferrer">#nikecraftweartester</a> Pen Pals". I actually made that zine into its own NFT collection and sent the digital copies to the physical copy owners. See where this is kinda going...</p>
                        <br/>
                        <p>I want you to know that your stamp isn't useless without ink and paper - I love stamping random things around the house, like my geodesic cat dome (an "i.s.r.u" project), light switches, anything that looks fun to brand. I've also had success using stamps on clay (without ink), bananas (a fun grocery prank), and even as temporary tattoos.</p>
                    </div>
                </Col>
                <Col sm={12} lg={6} style={{padding: "5%"}} >
                    <div className="prev-work-right-side" >
                        <Carousel >
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={janes1} alt="First slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={brads1} alt="Second slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={smith1} alt="Third slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={nasaafter} alt="First slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={nasawormzine} alt="Second slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={catdome} alt="Third slide"/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" style={{marginLeft: "auto", marginRight: "auto"}} src={hellokitty} alt="Third slide"/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </Col>
            </Row>
        </Container>
    );

};
