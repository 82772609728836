import "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterSection from '../components/FooterSection';
import Spacer from '../components/Spacer';
import Whitepaper from "../components/Whitepaper";

function Whitepage (){
    return(
        <div className="App" id="whitepaper-page">
            <Spacer/>
            <Whitepaper/>
            <Spacer/>
            <FooterSection/>
        </div>
    );

}

export default Whitepage;