import "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import MyStampSection from "../components/MyStampSection"
import FooterSection from '../components/FooterSection';
import Spacer from '../components/Spacer';

function MyStamps() {
  return (
      <div id="my-stamps-page">
        <MyStampSection/>
        <Spacer/>
        <FooterSection/>
      </div>
  );
}

export default MyStamps;
