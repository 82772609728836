import "../App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutSection from '../components/AboutSection';
import MintSection from '../components/MintSection';
import Roadmap from '../components/Roadmap';
import FAQSection from "../components/FAQSection";
import FooterSection from '../components/FooterSection';
import Spacer from '../components/Spacer';
import PreviousWork from "../components/PreviousWork";
import Whitepaper from "../components/Whitepaper";

function Home() {
  return (
      <div className="App" id="home">
        <Spacer/>
        <AboutSection youtubeLink="https://www.youtube.com/embed/jB90Ot8aPEw?controls=0&rel=0"/>
        <Spacer/>
        <Spacer/>
        <MintSection/>
        <Spacer/>
        <Spacer/>
        <Roadmap/>
        <Spacer/>
        <Spacer/>
        <Whitepaper/>
        <Spacer/>
        <Spacer/>
        <PreviousWork/>
        <Spacer/>
        <Spacer/>
        <FAQSection/>
        <Spacer/>
        <Spacer/>
        <FooterSection/>
      </div>
  );
}

export default Home;
