import './App.css';
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { BrowserRouter as Router, Route } from "react-router-dom";
import TopNav from './components/TopNav'
import Home from "./pages//home";
import MyStamps from "./pages/myStamps";
import Stamp from './pages/stamp';
import Whitepage from "./pages/whitepaper";
import "./mediaqs.css";

function App() {
  function getLibrary(provider) {
    const library = new Web3Provider(provider)
    library.pollingInterval = 12000
    return library
  }

  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <TopNav />
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/my-stamps" component={MyStamps} />
          <Route path="/stamp/:id" component={Stamp} />
          <Route path="/whitepaper" component={Whitepage} />
        </Router>
      </Web3ReactProvider>
    </div>
  );
}

export default App;


