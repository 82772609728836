import rm30p from "../assets/rm30p.png";
import rm60p from "../assets/rm60p.png";
import rm100p from "../assets/rm100p.png";
import roadmapHeader from "../assets/Roadmap.png";
import Spacer from "./Spacer";


export default function Roadmap(){

    return(
        <div className="container" id="roadmap-section" style={{width: "80%"}}>
            <img src={roadmapHeader} alt="Roadmap section header" className="header_image" />
            <Spacer/>
            <img src={rm30p} alt="roadmap at 30 percent" style={{width: "100%", marginBottom: "20px"}}/>
            <Spacer/>
            <div className="row">
                <div className="col" style={{width: "80%"}}>
                    <h2>At 30% minted:</h2>
                    <ul className="list-unstyled">
                        <li>Stamp carving videos will be uploaded to the website</li>
                        <li>2 stamp holders will get a custom t-shirt sent to them</li>
                        <li>1 airdrop to a *qualified social media follower</li>
                    </ul>
                </div>
            </div>
            <Spacer/>
            <img src={rm60p} alt="roadmap at 60 percent" style={{width: "100%", marginBottom: "20px"}}/>
            <Spacer/>
            <div className="row">
                <div className="col" style={{width: "80%"}}>
                    <h2>At 60% minted:</h2>
                    <ul className="list-unstyled">
                        <li>All physical stamps will be made available to holders - shipping globally free of charge (rolling forward)</li>
                        <li>2 more stamp holders will get a custom t-shirt</li>
                        <li>2 more airdrops to *qualified social media followers</li>
                    </ul>
                </div>
            </div>
            <Spacer/>
            <img src={rm100p} alt="roadmap at 100 percent" style={{width: "100%", marginBottom: "20px"}}/>
            <Spacer/>
            <div className="row">
                <div className="col" style={{width: "80%"}}>
                    <h2>At 100% minted:</h2>
                    <ul className="list-unstyled">
                        <li>T-shirts will be made for all holders</li>
                        <li><strong>**Pawlak Productions project 2 whitelisting</strong></li>
                    </ul>
                </div>
            </div>
            <Spacer/> 
            <p>*To be qualified you must: own an ETH wallet, be <a className="text-decoration-none" href="https://twitter.com/ethstampfactory" target="_blank" rel="noreferrer">following us on Twitter</a>, and be a member of <a href="https://discord.gg/XyVmFQpz" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>our Discord server</a>.</p>
            <p>**Announcement coming by EOY 2021</p>
        </div>
    );
};